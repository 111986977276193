<template>
  <div
    v-if="balance"
    class="row items-center text-gray-8"
  >
    <q-spinner
      v-if="accountsLoading"
      color="light-blue-9"
      size="1rem"
      class="q-mr-md"
    />

    <q-btn v-else flat @click="handleClick('/billing/accounts')">
      <span v-if="currencySymbol">
          {{ currencySymbol }}
        </span>

      <span v-if="balance">
          {{ balance }}
        </span>
    </q-btn>

    <q-btn
        v-if="0"
      icon="refresh"
      flat
      text-color="light-blue-9"
      @click="loadItems"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClientBalance',
  computed: {
    ...mapGetters([
      'accounts',
      'accountsLoading'
    ]),
    balance () {
      return this.accounts.reduce((acc, account) => {
        return acc + Number(account.balance)
      }, 0).toFixed(2)
    },
    currencySymbol () {
      const account = this.accounts.find(account => account._embedded && account._embedded.currency)

      if (!account) {
        return null
      }

      return account._embedded.currency.symbol
    }
  },
  mounted () {
    if (this.accounts.length === 0 && JSON.parse(localStorage.getItem('userData'))) {
      this.loadItems()
    }
  },
  methods: {
    ...mapActions([
      'loadAccounts'
    ]),
    handleClick (route) {
      this.$router.push(route)
    },
    loadItems () {
      const query = {
        per_page: 2,
        page: 1
      }

      return this.loadAccounts(query)
    }
  }
}
</script>
