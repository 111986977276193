<template>
    <q-btn-dropdown
        unelevated
        no-caps
        dense
        flat
        dropdown-icon="notifications"
        :style="{ paddingRight: '13px' }"
    >
      <template v-slot:label>
        <q-tooltip>{{ $t('Notifications') }}</q-tooltip>

        <q-badge
            :color="localNotifications.length > 0 ? 'yellow-6' : 'transparent'"
            :label="localNotifications.length"
            floating
        />
      </template>

      <div class="row q-px-md q-py-sm border-bottom items-center justify-between">
        <div>
          <span class="q-mr-sm">{{ $t('Notifications') }}</span>

          <q-badge
              color="orange"
              text-color="black"
              :label="localNotifications.length"
          />
        </div>

        <q-btn
            flat
            :label="$t('Clear All')"
            @click="removeAllLocalNotifications"
        />
      </div>

      <div
          v-if="localNotifications.length <= 0"
          class="text-center q-py-sm"
          style="min-width: 250px;"
      >
        {{ $t('No notifications') }}
      </div>

      <q-list
          v-else
          style="min-width: 250px;"
      >
        <q-item
            v-for="notification in localNotifications"
            :key="notification.id"
            v-close-popup
            clickable
        >
          <q-item-section>
            <header-notification
                :notification="notification"
                @click="onItemClick"
                @remove="removeLocalNotification"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import HeaderNotification from '../header-notification/HeaderNotification'

export default {
  name: 'NotificationsDropdown',
  emits: ['notification'],
  components: {
    HeaderNotification
  },
  computed: {
    ...mapGetters([
      'localNotifications'
    ])
  },
  methods: {
    ...mapMutations([
      'openCloseSidebar',
      'removeLocalNotification',
      'removeAllLocalNotifications'
    ]),
    onItemClick (data) {
      this.$emit('notification', data)
    }
  }
}
</script>

<style>
.q-btn-dropdown .row {
  margin: 0;
  padding: 0 0.5rem;
}
</style>
