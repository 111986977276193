<template>
  <q-btn-dropdown
    unelevated
    no-caps
    dropdown-icon="push_pin"
    dense
    flat
    v-if="$q.screen.gt.sm"
    :style="{ paddingRight: '13px' }"
  >
    <template v-slot:label>
        <q-tooltip>{{ $t('Shortcuts') }}</q-tooltip>
        <q-badge
            :color="routeShortcuts.length <= 0 ? 'transparent' : 'light-blue-6'"
            floating
        >
          {{ routeShortcuts.length}}
        </q-badge>
    </template>

    <div>
      <div
        v-if="routeShortcuts.length <= 0"
        class="text-center q-py-sm text-white"
        style="min-width: 200px; max-width: 300px;"
      >
        {{ $t('Nothing is pinned!') }}
      </div>

      <div
        v-else
        class="row q-pa-sm"
      >
        <div
          v-for="shortcut in routeShortcuts"
          :key="shortcut.route"
          :class="`col-${size} q-pa-xs`"
          style="min-height: 100px; min-width: 100px;"
        >
          <q-card
            class="text-center fit"
            style="min-height: 100px;"
          >
            <q-card-section class="q-py-none q-px-xs text-right">
              <q-icon
                name="close"
                size="16px"
                @click="removeRouteToShortcuts(shortcut)"
              />
            </q-card-section>

            <q-card-section class="q-py-none">
              <q-icon
                name="local_offer"
                size="25px"
              />
            </q-card-section>

            <q-card-section
              :class="`clickable q-pb-xs text-${shortcut.description ? 'caption' : 'subtitle2'}`"
              @click="copy(shortcut)"
            >
              {{ shortcut.name }}

              <q-icon name="attach_file" size="10px" class="q-mb-xs" />
            </q-card-section>

            <q-card-section v-if="shortcut.description" class="q-py-none text-subtitle2">
              {{ shortcut.description }}
            </q-card-section>

            <q-card-section v-if="shortcut.caption" class="q-py-xs text-caption">
              {{ shortcut.caption }}
            </q-card-section>

            <q-card-section class="q-py-xs text-caption">
              <q-btn
                color="light-blue-9"
                :label="$t('Go to the page')"
                size="sm"
                @click="handleClick(shortcut.route)"
              />
            </q-card-section>

            <q-menu
              touch-position
              context-menu
            >
              <q-list dense style="min-width: 100px">
                <q-item clickable v-close-popup @click="removeRouteToShortcuts(shortcut)">
                  <q-item-section>
                    {{ $t('Delete') }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-card>
        </div>
      </div>

      <div class="text-center q-pa-sm border-top">
        <q-btn
          color="danger"
          :label="$t('Delete All')"
          @click="deleteAllRouteShortcuts"
        />
      </div>
    </div>
  </q-btn-dropdown>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Utils
import { doCopy } from '../../utils/copy-utils'

export default {
  name: 'Shortcuts',
  computed: {
    ...mapGetters([
      'routeShortcuts'
    ]),
    size () {
      if (this.routeShortcuts.length <= 1) {
        return 12
      }

      if (this.routeShortcuts.length <= 2) {
        return 6
      }

      return 4
    }
  },
  methods: {
    ...mapMutations([
      'removeRouteToShortcuts',
      'deleteAllRouteShortcuts'
    ]),
    copy (item) {
      return doCopy(item.name)
    },
    handleClick (route) {
      this.$router.push(route)
    }
  }
}
</script>
