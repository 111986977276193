import { authHeader } from '../../helpers'
import { errorCatcher, handleResponse } from '../handlers'

/**
 * Get Badge
 *
 * @param {String | Number} userId
 *
 * @returns {Promise<any>}
 */
export function getBadge (userId) {
  const controller = new AbortController()
  const Accept = 'text/html,application/xhtml+xml,application/xml;'

  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      Accept
    },
    signal: controller.signal
  }

  return fetch(`${window.appOptions.defaultServer}/users/badge/${userId}`, requestOptions)
    .then(data => handleResponse(data, 'GET', Accept))
    .catch(errorCatcher)
}
