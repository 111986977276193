<template>
  <div class="row items-center justify-between clickable">
    <span @click="handleClick">
      {{ message }}
    </span>

    <q-btn
      color="negative"
      icon="delete"
      size="sm"
      class="q-ml-md"
      round
      @click="removeLocalNotification"
    />
  </div>
</template>

<script>
export default {
  name: 'HeaderNotification',
  emits: ['click', 'remove'],
  props: {
    notification: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    route () {
      const types = {
        'Orderadmin\\Products\\Entity\\AbstractOrder': (id) => {
          return `/order/unknown/${id}`
        }
      }

      if (types[this.notification.data.entityClass]) {
        return types[this.notification.data.entityClass](this.notification.data.entity)
      }

      return false
    },
    message () {
      return this.notification.data.message
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', this.notification)
    },
    removeLocalNotification () {
      this.$emit('remove', this.notification.id)
    }
  }
}
</script>
