<template>
  <div>
    <q-dialog
      v-model="isOpen"
      :maximized="isFullScreen"
    >
      <q-card
        v-if="user"
        class="relative"
        style="min-width: 32vw; max-width: 100vw;"
        :class="appOptions.theme"
      >
        <q-card-section :class="`row justify-end ${bg}`">
          <q-btn
            v-close-popup
            dense
            flat
            icon="close"
          >
            <q-tooltip>{{ $t('Close') }}</q-tooltip>
          </q-btn>
        </q-card-section>

        <q-card-section :class="`row justify-center ${bg} q-pt-none`">
          <q-avatar size="90px">
            <img src="https://cdn.quasar.dev/img/boy-avatar.png">
          </q-avatar>
        </q-card-section>

        <q-card-section :class="`${bg} q-pt-none`">
          <div class="text-center q-mb-sm">
            <strong class="d-inline-block">{{ user.username || $t('none') }}</strong>

            <q-icon
              name="edit"
              class="q-ml-sm"
            />

            <q-popup-edit
              :model-value="user.username"
              :title="$t('Edit username')"
            >
              <q-input
                :model-value="user.username"
                dense
                autofocus
                @update:model-value="handleUpdate('username', $event)"
              />
            </q-popup-edit>
          </div>

          <div class="row justify-center text-center q-px-md">
            <div
              v-for="role in (user.roles || [])"
              :key="role.id"
              class="q-px-sm border-left border-right"
            >
              {{ role.name.length > 16 ? `${role.name.slice(0, 16)}...` : role.name }}
            </div>
          </div>
        </q-card-section>

        <q-card-section class="border-top q-pt-lg">
          <div class="row">
            <div class="col">
              <div class="q-mb-sm">
                <strong>
                  {{ $t('Email') + ': ' }}
                </strong>

                <span class="d-inline-block">{{ user.email || $t('none') }}</span>

                <q-icon
                  name="edit"
                  class="q-ml-sm"
                />

                <q-popup-edit
                  :model-value="user.email"
                  :title="$t('Edit email')"
                >
                  <q-input
                    :model-value="user.email"
                    dense
                    autofocus
                    @update:model-value="handleUpdate('email', $event)"
                  />
                </q-popup-edit>
              </div>

              <div
                v-if="hasNotUserRole"
                class="q-mb-sm"
              >
                <strong>
                  {{ $t('Domains') + ': ' }}
                </strong>

                <span class="d-inline-block">{{ (user.domains || []).join(', ') || $t('none') }}</span>

                <q-popup-edit
                  :model-value="user.domains"
                  :title="$t('Edit domains')"
                >
                  {{ user.domains }}
                </q-popup-edit>
              </div>
            </div>

            <div>
              <div class="q-mb-sm">
                <strong>
                  {{ $t('Password') + ': ' }}
                </strong>

                <span class="d-inline-block">{{ $t('hidden') }}</span>

                <q-icon
                  name="edit"
                  class="q-ml-sm"
                />

                <q-popup-edit
                  :model-value="user.password"
                  :title="$t('Edit password')"
                >
                  <q-input
                    :model-value="user.password"
                    dense
                    autofocus
                    @update:model-value="handleUpdate('password', $event)"
                  />
                </q-popup-edit>
              </div>

              <div
                v-if="hasNotUserRole"
                class="q-mb-sm"
              >
                <strong>
                  {{ $t('Domain') + ': ' }}
                </strong>

                <span class="d-inline-block">{{ user.domain || $t('none') }}</span>

                <q-icon
                  name="edit"
                  class="q-ml-sm"
                />

                <q-popup-edit
                  :model-value="user.domain"
                  :title="$t('Edit domein')"
                >
                  <q-input
                    :model-value="user.domain"
                    dense
                    autofocus
                    @update:model-value="handleUpdate('domain', $event)"
                  />
                </q-popup-edit>
              </div>
            </div>

            <div
              v-if="user.eav && hasOperatorRole"
              class="col-12 q-mb-sm"
            >
              <strong>
                {{ $t('Interactions operator number') + ': ' }}
              </strong>

              <span class="d-inline-block">{{ user.eav['interactions-operator-number'] || $t('none') }}</span>

              <q-icon
                name="edit"
                class="q-ml-sm"
              />

              <q-popup-edit
                :model-value="user.eav['interactions-operator-number']"
                :title="$t('Edit interactions operator number')"
              >
                <q-input
                  :model-value="user.eav['interactions-operator-number']"
                  dense
                  autofocus
                  @update:model-value="handleEavUpdate('interactions-operator-number', $event)"
                />
              </q-popup-edit>
            </div>
          </div>
        </q-card-section>

        <div class="row justify-center items-end q-pb-md">
          <q-btn
            class="q-mr-sm q-mb-xs"
            color="danger"
            :label="$t('Log out')"
            push
            @click="handleLogout"
          />

          <q-btn
            class="q-mr-sm q-mb-xs"
            color="blue"
            :label="$t('Badge')"
            push
            @click="printBadge"
          />

          <q-btn
            class="q-mr-sm q-mb-xs"
            color="orange"
            :label="$t('History')"
            push
            @click="handleHistory"
          />

          <q-btn
            class="q-mb-xs"
            color="light-blue-9"
            :label="$t('Save')"
            push
            @click="handleSave"
          />
        </div>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isOpenHistory">
      <q-card
        v-if="user"
        class="q-pa-md"
        style="width: 700px; max-width: 80vw;"
      >
        <history
          :id="user.id"
          :entity="'Users\\Entity\\User'"
        />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import History from './../history/history'

// Services
import { getBadge } from './../../services/requests/badge.service'
import { UserService } from '../../services'

export default {
  name: 'User',
  components: {
    History
  },
  data () {
    return {
      isOpen: false,
      isOpenHistory: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'appOptions'
    ]),
    hasOperatorRole () {
      return this.user.roles.find(({ id }) => `${id}` === `31`)
    },
    hasNotUserRole () {
      return !this.user.roles.find(({ id }) => `${id}` === `2`)
    },
    bg () {
      return this.$q.dark.isActive
        ? ''
        : 'bg-blue-grey-1'
    },
    isFullScreen () {
      return this.isOpen && window.innerWidth < 700
    },
    badgeLink () {
      return `${this.appOptions.defaultServer}/users/badge/${this.user.id}`
    }
  },
  methods: {
    ...mapMutations([
      'updateUser',
      'setUser'
    ]),
    ...mapActions([
      'loadUserAccount',
      'saveUser'
    ]),
    handleLogout () {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      UserService.logout()
      this.$eventBus.update('rules', { user: null })
    },
    // For now we have badge only in old interface
    // For this we need to authorized with our refresh token and after this to go to page with badge
    printBadge () {
      const user = JSON.parse(localStorage.getItem('user'))
      const win = window.open(`${this.appOptions.defaultServer}/users/login/terminal?token=${user.refresh_token}`, '_blank')
      win.focus()
      this.loadBadge(win)
    },
    // Check is page is redirected to home page
    // If it is on home page then go to badge
    // Max number of try is 10
    loadBadge (win, times = 10) {
      setTimeout(() => {
        if (win.document.URL !== this.appOptions.defaultServer && times > 0) {
          this.loadBadge(win, times - 1)
        }

        win.open(`${this.appOptions.defaultServer}/users/badge/${this.user.id}`, '_self')
      }, 1000)
    },
    updateLocalStorage (user) {
      localStorage.setItem('userData', JSON.stringify(user))
    },
    init () {
      this.loadUserAccount()
        .then(() => {
          if (!this.user.eav) {
            this.updateUser({ eav: {} })
          }

          this.updateLocalStorage(this.user)
        })
    },
    handleEavUpdate (key, value) {
      this.updateUser({
        eav: {
          ...(this.user.eav || {}),
          [key]: value
        }
      })
    },
    handleUpdate (key, value) {
      this.updateUser({ [key]: value })
    },
    openCloseProfile () {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        this.init()
      }
    },
    handleSave () {
      this.saveUser()
        .then(user => {
          this.updateLocalStorage(this.user)
          this.setUser(user)
        })
    },
    handleBadge () {
      getBadge(this.user.id)
        .then(console.debug)
    },
    handleHistory () {
      this.isOpenHistory = true
    }
  }
}
</script>
